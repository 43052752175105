@import "bootstrap/dist/css/bootstrap.min.css";
@import "bootstrap-icons/font/bootstrap-icons.css";

.view-loading {
    top: calc(50% - (var(--loading-spinner-size) / 2));
    left: calc(50% - (var(--loading-spinner-size) / 2));
}

.view-loading-spinner {
    --loading-spinner-size: 5rem;
    width: var(--loading-spinner-size);
    height: var(--loading-spinner-size);
}

.has-won-sign {
    position: absolute;
    right: 0;
}

.view-page .spacer {
    /* scroll past the content */
    height: 25vh;
}