#results-container {
    background-color: white;
    height: 100%;
}

#results-container .col {
    text-align: center;
}

#results-bar-column {
    /* 10em is for the player name + the position */
    height: calc(100% - 10em);
}

#results-playername-column {
    font-size: xx-large;
}

.results-bar {
    --width: 75px;
    width: var(--width);

    bottom: 25px;
    /* center it */
    left: calc(50% - (var(--width) / 2));
}

.results-label {
    font-size: x-large;

    /* center it */
    left: 0;
    width: 100%;
}

.results-positions-label {
    font-size: 55px;
}

#confetti-canvas {
    z-index: 10;
    top: 0;
    left: 0;
}

.results-buttons {
    top: 5px;
    right: 5px;
    cursor: pointer;
    /* place it above the confetti canvas */
    z-index: 20;
}