p.card-text {
    margin-bottom: 0.5rem;
}

.game-card {
    cursor: pointer;
    transition: all 0.2s;
    /* .game-card is a <a>, so remove the default underline from it */
    text-decoration: none;
}

.game-card:hover {
    transform: scale(1.05);
}